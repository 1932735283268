// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-trainer-template-tsx": () => import("./../../../src/pages/trainer-template.tsx" /* webpackChunkName: "component---src-pages-trainer-template-tsx" */),
  "component---src-pages-trainers-tsx": () => import("./../../../src/pages/trainers.tsx" /* webpackChunkName: "component---src-pages-trainers-tsx" */),
  "component---src-pages-user-panel-tsx": () => import("./../../../src/pages/user-panel.tsx" /* webpackChunkName: "component---src-pages-user-panel-tsx" */),
  "component---src-pages-verify-email-tsx": () => import("./../../../src/pages/verify_email.tsx" /* webpackChunkName: "component---src-pages-verify-email-tsx" */),
  "component---src-pages-zaloguj-tsx": () => import("./../../../src/pages/zaloguj.tsx" /* webpackChunkName: "component---src-pages-zaloguj-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-category-list-template-tsx": () => import("./../../../src/templates/category-list-template.tsx" /* webpackChunkName: "component---src-templates-category-list-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/category-template.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-video-template-tsx": () => import("./../../../src/templates/video-template.tsx" /* webpackChunkName: "component---src-templates-video-template-tsx" */)
}

