import { DefaultTheme } from "styled-components"

const myTheme: DefaultTheme = {
  color: {
    darkGreen: "#263339",
    darkestGreen: "#1A2428",
    lightGreen: "#4EF2B8",
    gray: "#8c8c8c",
    white: "#ffffff",
    black: "#1a1a1a",
    rose: "#F9808E",
  },
  fontSize: {
    verySmall: "0.8rem",
    small: "1.125rem",
    average: "1.4rem",
    medium: "2.19rem",
    huge: "3rem",
    big: "4.375rem",
    giant: "6.19rem",
    gigantic: "9rem",
  },
  fontWeight: {
    thin: "400",
    normal: "500",
    bold: "600",
  },
  lineHeight: {
    average: "26px",
  },
}

export { myTheme }
