import React from "react"
import {ThemeProvider} from "styled-components"
import {myTheme} from "./src/my-theme.ts"
import {Auth0Provider} from "@auth0/auth0-react";

export const wrapRootElement = ({element}) => (
    <Auth0Provider domain={process.env.GATSBY_AUTH0_DOMAIN} clientId={process.env.GATSBY_AUTH0_CLIENT_ID} redirectUri={window.location.origin}>
        <ThemeProvider theme={myTheme}>{element}</ThemeProvider>
    </Auth0Provider>
)
